nav.template-main-menu > ul > li a.world-lang:after {
  content: "\f02c";
  font-family: IcoFont !important;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  padding-left: 6px;
  transition: all 0.3s ease-in-out;
}

.sub-menu-language {
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 0) !important;
}

.sub-menu-language:before {
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 0) !important;
}

.sub-menu-language li:hover,
.sub-menu-language li p:hover {
  cursor: pointer;
  color: #5edfff;
}
