.is-active {
  color: #5edfff !important;
}

nav.template-main-menu > ul > li ul.sub-menu-routes {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0) !important;
  pointer-events: auto;
}

nav.template-main-menu > ul > li ul.sub-menu-routes {
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 0) !important;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 30%) !important;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out !important;
}

.sub-menu-routes:before {
  left: 46% !important;
  right: auto !important;
  transition: all 0.3s ease-in-out !important;
}
