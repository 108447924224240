.fixed-sidebar .sidebar-menu-wrap .top-menu-mobile .menu-label a {
  color: #a8b1bc;
}

.fixed-sidebar .sidebar-menu-wrap .top-menu-mobile .menu-label a.active {
  color: #5edfff;
}

.fixed-sidebar .sidebar-menu-wrap .top-menu-mobile .menu-link.active {
  color: #5edfff;
}

@media only screen and (min-width: 1199px) {
  .public-sidebar {
    display: none;
  }
}
