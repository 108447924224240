.cs-profile-account .cs-switch-profile-close {
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  color: #615dfa;
}

.cs-profile-account .cs-switch-profile-close:before {
  font-size: 22px;
}

/* .cs-profile-account .modal-body {
  padding-top: 2.5rem;
} */

.cs-switch-profile .cs-profile-account {
  flex: 1 1 100px;
  align-items: center;
  justify-content: center;
}

.cs-profile-account .cs-profile-img .cs-profile-detail {
  cursor: pointer;
  position: absolute;
  top: -16px;
  border-radius: 50%;
  background: #615dfa;
  color: #fff;
  padding: 0px 8px;
}

.cs-profile-account .cs-profile-img .cs-profile-detail:before {
  font-size: 14px;
}

.cs-profile-account .cs-profile-img .cs-profile-detail:hover {
  cursor: pointer;
  position: absolute;
  top: -16px;
  border-radius: 50%;
  background: #5854ef;
  color: #fff;
  padding: 0px 8px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cs-profile-account .cs-profile-img a {
  border-radius: 50%;
  border: 4px solid rgba(250, 250, 250, 1);
  box-shadow: rgba(250, 250, 250, 1) 0px 2px 8px 0px;
}

.cs-profile-account .cs-profile-img .cs-profile-create {
  background-color: #eff4fb;
  color: #615dfa;
  border-radius: 50%;
  border: 4px solid #eff4fb;
  box-shadow: #eff4fb 0px 2px 8px 0px;
  width: 100px;
  height: 100px;
  margin-bottom: 8px;
}

.cs-profile-account .cs-profile-img .cs-profile-create:hover {
  background-color: #5854ef;
  color: #fff;
  border-radius: 50%;
  border: 4px solid #5854ef;
  box-shadow: rgba(250, 250, 250, 0.2) 0px 2px 8px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cs-profile-account .cs-profile-img a:hover {
  border-radius: 50%;
  border: 4px solid #5854ef;
  box-shadow: rgba(250, 250, 250, 0.2) 0px 2px 8px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cs-profile-account .cs-profile-img a.active {
  border-radius: 50%;
  border: 4px solid #615dfa;
  box-shadow: rgba(97, 93, 250, 0.4) 0px 1px 8px 0px;
}

.cs-profile-account .cs-profile-img a.active:hover {
  border-radius: 50%;
  border: 4px solid #5854ef;
  box-shadow: rgba(97, 93, 250, 0.4) 0px 1px 8px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cs-profile-account .cs-profile-img a img {
  border-radius: 50%;
  box-shadow: rgba(97, 93, 250, 0.2) 0px 2px 8px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cs-switch-profile .cs-profile-account .cs-profile-detail-button {
  background-color: #cccccc;
  color: #fff;
  border-radius: 50%;
  box-shadow: rgba(97, 93, 250, 0.2) 0px 2px 8px 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cs-switch-profile .cs-profile-account .cs-profile-detail-button:hover {
  cursor: pointer;
  background-color: #615dfa;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cs-profile-account .modal-content {
  border: none;
}

.cs-profile-account .modal-content .modal-footer {
  border-top: none !important;
}

.cs-profile-account .modal-content .modal-footer button {
  border: none;
  background-color: #615dfa;
}

.cs-profile-account .modal-content .modal-footer button:hover {
  background-color: #5854ef;
}
